import { Typography, Box, IconButton, Button, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Close, VisibilityOutlined, HelpOutlineOutlined } from "@mui/icons-material";

import { ReactComponent as ComunicaLogo } from "~/assets/comunica-logo.svg";
import { colors } from "~/styles";

export const ButtonBack = styled(Box)(() => ({
	"&:hover": {
		cursor: "pointer",
	},
}));

export type TMenuView = {
	handleGoBack: () => void;
};

export const MenuView = ({ handleGoBack }: TMenuView) => {
	return (
		<Box display="flex" justifyContent="center" sx={{ borderBottom: "1px solid #dcdee6" }}>
			<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 100px" py={1.5} sx={{ borderRight: "1px solid #dcdee6" }}>
				<SvgIcon sx={{ maxWidth: 35, maxHeight: 35 }} component={ComunicaLogo} viewBox={`0 0 110.96 127.46`} onClick={handleGoBack} />
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" flex="1 1 calc(100% - 300px)" px={1.5} py={1.5}>
				<Typography fontSize="18px" fontWeight="500" color={colors.gray8} ml={2}>
					Comunicado em construção
				</Typography>
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 180px" gap={1} pr={1.5} py={1.5}>
				<IconButton type="button" aria-label="search">
					<HelpOutlineOutlined />
				</IconButton>

				<IconButton type="button" aria-label="search">
					<VisibilityOutlined />
				</IconButton>

				<Button variant="contained">Enviar</Button>
			</Box>

			<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 50px" sx={{ borderLeft: "1px solid #dcdee6" }} py={1.5}>
				<IconButton type="button" aria-label="search" onClick={handleGoBack}>
					<Close />
				</IconButton>
			</Box>
		</Box>
	);
};
