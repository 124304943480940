export const colors = {
	/* UI Gray Scale */
	gray0: "#F8F9FA",
	gray1: "#F9F9F9",
	gray2: "#747474",
	gray3: "#DEE2E6",
	gray4: "#CED4DA",
	gray5: "#6B6B6B",
	gray6: "#868E96",
	gray7: "#495057",
	gray8: "#151515",
	gray9: "#212529",
	gray10: "#111315",

	/* Purple */
	purple0: "#7237F2",
	purple1: "#E4D7FF",

	/* Basic */
	white: "#FFFFFF",

	/* Neutral black */
	black: "#000000",

	/* Complementary colors */
	red1: "#FFA8A8",
	green1: "#D3F9D8",
};
